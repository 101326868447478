/* eslint-disable no-unreachable */
import BCardCode from '@core/components/b-card-code'
import {
  BNav, BNavItem, BNavItemDropdown, BDropdownDivider, BDropdownItem, BForm, BFormInput, BRow, BCol,
  BFormGroup, BButton, BCard, BDropdown, BSidebar, BFormRadioGroup, BFormRadio, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, min, max, alpha_num, integer,
} from '@validations'

import { drivingRangeSetting } from '@/api/driving-range-setting'

export default {
  name: 'DrivingRange',
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormRadioGroup,
    BFormRadio,
    BSidebar,
    BCardCode,
    BOverlay,
    BNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
    BCard,
    BDropdown,
  },
  data() {
    return {
      listDrivingRange: [],
      currentSelected: null,
      currentLang: this.$i18n.locale,
      isOpen: false,
      detailDrivingRange: {},
      addNewDrivingRange: {},
      isLoading: false,
    }
  },
  created() {
    // Dịch vee validate
    if (this.currentLang == 1000000) {
      localize('vi')
    } else {
      localize('en')
    }

    this.getListDrivingRange_RDR01_AC1()
  },
  methods: {
    async getListDrivingRange_RDR01_AC1() {
      await drivingRangeSetting.getListDrivingRange()
        .then(res => {
          if (res) {
            this.listDrivingRange = res.Data.DrivingRange
          }
        })
    },
    addNewDrivingRange_RDR01_AC2() {
      this.$refs.drivingRangeForm.validate().then(success => {
        if (success) {
          const body = {
            DrivingRange: {
              DrivingRangeCode: this.addNewDrivingRange.DrivingRangeCode,
              DrivingRangeName: this.addNewDrivingRange.DrivingRangeName,
              Inventory: parseInt(this.addNewDrivingRange.Inventory),
            },
          }
          drivingRangeSetting
            .addNewDrivingRange(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status == 200) {
                this.isOpen = false
                this.getListDrivingRange_RDR01_AC1()
              }
            })
        }
      })
    },
    async getDetailDrivingRange_RDR01_AC3(drivingRangeId) {
      this.currentSelected = drivingRangeId
      this.isLoading = true
      const body = {
        DrivingRange: {
          Id: drivingRangeId,
        },
      }
      await drivingRangeSetting
        .getDetailDrivingRange(body)
        .then(res => {
          if (res.Status == 200) {
            this.detailDrivingRange = res.Data.DrivingRange
          }
        })
      this.isLoading = false
    },
    updateDrivingRange_RDR01_AC4() {
      this.$refs.updateDrivingRangeForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          const body = {
            DrivingRange: {
              Id: this.currentSelected,
              DrivingRangeCode: this.detailDrivingRange.DrivingRangeCode,
              DrivingRangeName: this.detailDrivingRange.DrivingRangeName,
              Inventory: parseInt(this.detailDrivingRange.Inventory),
            },
          }
          drivingRangeSetting
            .updateDrivingRange(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status == 200) {
                this.getListDrivingRange_RDR01_AC1()
              }
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    deleteDrivingRange_RDR01_AC5(drivingRangeId) {
      const body = {
        DrivingRange: {
          Id: drivingRangeId,
        },
      }
      drivingRangeSetting
        .deleteDrivingRange(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.currentSelected = null
            this.getListDrivingRange_RDR01_AC1()
          }
        })
    },
    async deactiveDrivingRange_RDR01_AC6(drivingRangeId) {
      this.isLoading = true
      const body = {
        DrivingRange: {
          Id: drivingRangeId,
        },
      }
      await drivingRangeSetting
        .deactiveDrivingRange(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.getDetailDrivingRange_RDR01_AC3(drivingRangeId)
            this.getListDrivingRange_RDR01_AC1()
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
    async activeDrivingRange_RDR01_AC7(drivingRangeId) {
      this.isLoading = true
      const body = {
        DrivingRange: {
          Id: drivingRangeId,
        },
      }
      await drivingRangeSetting
        .activeDrivingRange(body)
        .then(res => {
          this.showResToast(res)
          if (res.Status == 200) {
            this.getDetailDrivingRange_RDR01_AC3(drivingRangeId)
            this.getListDrivingRange_RDR01_AC1()
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
  },
}
