/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import {
  ROOT,
  GET_LIST_DRIVING_RANGE,
  ADD_NEW_DRIVING_RANGE,
  GET_DETAIL_DRIVING_RANGE,
  UPDATE_DRIVING_RANGE,
  DELETE_UPDATE_DRIVING_RANGE,
  ACTIVE_DRIVING_RANGE,
  DEACTIVE_DRIVING_RANGE,
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function getListDrivingRange(data) {
  return fetch(ROOT + GET_LIST_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function addNewDrivingRange(data) {
  return fetch(ROOT + ADD_NEW_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function getDetailDrivingRange(data) {
  return fetch(ROOT + GET_DETAIL_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function updateDrivingRange(data) {
  return fetch(ROOT + UPDATE_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deleteDrivingRange(data) {
  return fetch(ROOT + DELETE_UPDATE_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function activeDrivingRange(data) {
  return fetch(ROOT + ACTIVE_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function deactiveDrivingRange(data) {
  return fetch(ROOT + DEACTIVE_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const drivingRangeSetting = {
  getListDrivingRange,
  addNewDrivingRange,
  getDetailDrivingRange,
  updateDrivingRange,
  deleteDrivingRange,
  activeDrivingRange,
  deactiveDrivingRange,
}
